//! 2 eslint error for same line
/* eslint-disable no-return-assign */
import React, { useContext, useState, useEffect } from "react";
import moment from "moment";

//! Antd imports
import Modal from "antd/lib/modal/Modal";
import { Divider, Input, Skeleton } from "antd";

//! User files
import useDebounce from "../../../../../common/useDebounce";
import { WarningIcon } from "../../../../../assets/svg";
import IconButton from "../../../../../common/components/IconButton/IconButton";
import SetSubscriptionFeatures from "./SetSubscriptionFeatures";
import {
  DEFAULT,
  GOOGLE,
  MONTH,
  PLAN_NAMES,
  PLAN_STATUS,
} from "../../../../../common/constants";
import { getCurrencySymbol } from "../../../../../common/utils";
import { AppContext } from "../../../../../AppContext";
import RemovePopup from "../../../../../common/components/RemovePopup/RemovePopup";

const SubscriptionPlans = ({
  products,
  handleSpaceSubscription,
  spaceDetail,
  handleUpdateSubscription,
  retrieveSubscription,
  subscriptionChanges,
  retrieveLoader,
  updateLoader,
  setOpen,
  open,
  newSpaceLoader,
  openCreditPopup,
  creditPopup,
  selectedInterval,
  updatedUserCount,
  setUpdatedUserCount,
  creditText,
  isLTDPlan,
}) => {
  const spaceSubscriptionDetail = spaceDetail?.subscription;
  const {
    state: { userSubscriptionInfo },
  } = useContext(AppContext);
  const [limitExceedPopup, openLimitExceedPopup] = useState(false);
  const [confirmPopup, openConfirmPopup] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [pricingPlan, setPricingPlan] = useState(null);

  const planEndDate = moment(spaceSubscriptionDetail?.endDate);
  const handleUpdatePopup = (
    priceId,
    amount,
    currency,
    planName,
    interval,
    plan
  ) => {
    setSelectedPlan({ priceId, price: amount, currency, planName, interval });
    if (
      (planName === PLAN_NAMES?.INDIVIDUAL &&
        userSubscriptionInfo?.membersCount >
          parseInt(plan?.features?.min_user_quota, 10)) ||
      userSubscriptionInfo?.contactsCount >
        parseInt(plan?.features?.f_contacts, 10) ||
      userSubscriptionInfo?.contactSources?.length >
        parseInt(plan?.features?.f_sync_sources, 10) ||
      (userSubscriptionInfo?.storage?.filesSize !== null &&
        userSubscriptionInfo?.storage?.filesSize >
          parseInt(plan?.features?.f_documents, 10) * 1024)
    ) {
      openLimitExceedPopup(true);
    } else {
      retrieveSubscription(priceId, planName);
      setOpen(true);
    }
  };

  const getUserDefaultValue = () => {
    if (
      userSubscriptionInfo?.type === GOOGLE &&
      spaceDetail?.subscription?.quantity <= 5
    ) {
      return 5;
      // eslint-disable-next-line no-else-return
    } else if (
      userSubscriptionInfo?.type === GOOGLE &&
      spaceDetail?.subscription?.quantity > 5
    ) {
      return spaceDetail?.subscription?.quantity;
    } else if (
      userSubscriptionInfo?.type === DEFAULT &&
      spaceDetail?.subscription?.quantity <= 3
    ) {
      return 3;
    } else if (
      userSubscriptionInfo?.type === DEFAULT &&
      spaceDetail?.subscription?.quantity > 3
    ) {
      return spaceDetail?.subscription?.quantity;
    } else if (
      userSubscriptionInfo?.type === DEFAULT &&
      spaceDetail?.subscription?.quantity === null
    ) {
      return 3;
    } else if (
      userSubscriptionInfo?.type === GOOGLE &&
      spaceDetail?.subscription?.quantity === null
    ) {
      return 5;
    } else if (
      userSubscriptionInfo?.type === null &&
      userSubscriptionInfo?.type !== DEFAULT &&
      spaceDetail?.subscription?.quantity > 3
    ) {
      return spaceDetail?.subscription?.quantity;
    }

    return 3;
  };

  const userDefaultValue = getUserDefaultValue();
  const closeUpdatePopup = () => {
    setUpdatedUserCount(userDefaultValue);
    setOpen(false);
  };

  const closePopup = () => {
    setUpdatedUserCount(userDefaultValue);
    openLimitExceedPopup(false);
  };

  const handleNewSpacePlan = (
    priceId,
    amount,
    currency,
    planName,
    interval,
    plan
  ) => {
    if (
      userSubscriptionInfo?.contactsCount >
        parseInt(plan?.features?.f_contacts, 10) ||
      userSubscriptionInfo?.contactSources?.length >
        parseInt(plan?.features?.f_sync_sources, 10) ||
      (userSubscriptionInfo?.storage?.filesSize !== null &&
        userSubscriptionInfo?.storage?.filesSize >
          parseInt(plan?.features?.f_documents, 10) * 1024)
    ) {
      openLimitExceedPopup(true);
    } else {
      openConfirmPopup(true);
      setSelectedPlan({ priceId, price: amount, currency, planName, interval });
    }
  };

  const closeConfirmPopup = () => {
    setUpdatedUserCount(userDefaultValue);
    openConfirmPopup(false);
  };

  const closeCreditPopup = () => {
    openCreditPopup(false);
  };
  useEffect(() => {
    if (products?.length > 0) {
      const myProduct = [];
      if (!isLTDPlan) {
        products?.map((plan, i) => {
          if (
            spaceSubscriptionDetail?.product?.id === plan?.id &&
            spaceSubscriptionDetail?.product?.price?.interval ===
              selectedInterval &&
            spaceSubscriptionDetail?.planStatus !== PLAN_STATUS?.CANCELLED
          ) {
            // eslint-disable-next-line no-nested-ternary
            return plan?.name === PLAN_NAMES.INDIVIDUAL
              ? (myProduct[0] = spaceSubscriptionDetail?.product)
              : // eslint-disable-next-line no-nested-ternary
              plan?.name === PLAN_NAMES.BASIC
              ? (myProduct[1] = spaceSubscriptionDetail?.product)
              : plan?.name === PLAN_NAMES.PRO ||
                plan?.name === PLAN_NAMES.PROFESSIONAL
              ? (myProduct[2] = spaceSubscriptionDetail?.product)
              : (myProduct[3] = spaceSubscriptionDetail?.product);
            // eslint-disable-next-line no-else-return
          } else {
            // eslint-disable-next-line no-nested-ternary
            return plan?.name === PLAN_NAMES.INDIVIDUAL
              ? (myProduct[0] = products[i])
              : // eslint-disable-next-line no-nested-ternary
              plan?.name === PLAN_NAMES.BASIC
              ? (myProduct[1] = products[i])
              : plan?.name === PLAN_NAMES.PRO ||
                plan?.name === PLAN_NAMES.PROFESSIONAL
              ? (myProduct[2] = products[i])
              : (myProduct[3] = products[i]);
          }
        });
        setPricingPlan(myProduct);
      } else {
        setPricingPlan([spaceSubscriptionDetail?.product]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  useEffect(() => {
    if (userDefaultValue !== undefined) setUpdatedUserCount(userDefaultValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDefaultValue]);

  const maximumUserCount = 500;
  const updatedUserCounts = useDebounce(updatedUserCount, 500);
  useEffect(() => {
    if (
      spaceDetail?.subscription?.id !== null &&
      spaceDetail?.subscription?.planStatus !== PLAN_STATUS?.TRIAL &&
      updatedUserCounts !== undefined &&
      updatedUserCounts !== null &&
      spaceSubscriptionDetail?.planStatus !== PLAN_STATUS?.CANCELLED &&
      spaceSubscriptionDetail?.planStatus !== PLAN_STATUS?.EXPIRED &&
      updatedUserCount <= maximumUserCount &&
      (userSubscriptionInfo?.type === GOOGLE
        ? updatedUserCount >= 5
        : updatedUserCount >= 3)
    ) {
      retrieveSubscription(selectedPlan?.priceId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedUserCounts]);

  return (
    <>
      <div
        className={`cms-subs-plan-wrapper ${
          isLTDPlan && "m-auto align-center"
        }`}
      >
        {pricingPlan?.map((plan) =>
          !plan?.name?.toLowerCase().includes("professional") &&
          !plan?.name?.toLowerCase().includes("pro+") &&
          (plan?.name?.toLowerCase().includes("pro") ||
            plan?.name?.toLowerCase().includes("team")) ? (
            //! For pro plan
            <div
              className={`${
                spaceSubscriptionDetail?.product?.price?.id ===
                  plan?.price?.id &&
                spaceSubscriptionDetail?.planStatus === PLAN_STATUS?.ACTIVE &&
                "cms-plan-active"
              } ${isLTDPlan && "width-ltd"} cms-subs-plan cms-subs-pro-plan`}
              key={plan?.price?.id}
            >
              <div className="cms-plan-recommend-wrapper">
                {!isLTDPlan && (
                  <span
                    className={`${
                      spaceSubscriptionDetail?.product?.price?.id ===
                        plan?.price?.id &&
                      spaceSubscriptionDetail?.planStatus ===
                        PLAN_STATUS?.ACTIVE
                        ? "cms-plan-recommend-active"
                        : "cms-plan-recommend"
                    } font-kalam recommended-text cms-subs-plan-text`}
                  >
                    Recommended
                  </span>
                )}
              </div>

              <div className="cms-subs-plan-title cms-plan-title-other">
                <div className="cms-subs-active-plan">
                  <span className="font-inter cms-plan-title-text">
                    {plan?.name} Plan
                  </span>
                </div>
              </div>
              <div className="cms-subs-plan-title">
                <div className="cms-plan-price-detail cms-font-ellipsis">
                  <span className="font-inter cms-plan-price">
                    {plan?.price?.currency === "inr" ? "Rs." : "$"}
                    {
                      // eslint-disable-next-line no-nested-ternary
                      isLTDPlan
                        ? plan?.price?.amount
                            ?.toFixed(2)
                            ?.replace(/[.,]00$/, "")
                        : selectedInterval === MONTH
                        ? plan?.price?.amount
                            ?.toFixed(2)
                            ?.replace(/[.,]00$/, "")
                        : (plan?.price?.amount / 12)
                            ?.toFixed(2)
                            ?.replace(/[.,]00$/, "")
                    }
                  </span>
                  {!isLTDPlan && (
                    <span className="font-inter cms-plan-per-month">
                      /month
                    </span>
                  )}
                </div>
              </div>
              {!isLTDPlan && (
                <div className="cms-subs-plan-title per-member-title cms-plan-title-other">
                  <div className="cms-plan-price-detail cms-font-ellipsis">
                    <span className="font-inter cms-plan-per-user">
                      per member
                    </span>
                  </div>
                </div>
              )}
              <div className={`${!isLTDPlan && "cms-subs-plan-feature-list"}`}>
                <SetSubscriptionFeatures
                  features={plan?.features}
                  planName={plan?.name}
                  isLTDPlan={isLTDPlan}
                />
              </div>
              {(spaceSubscriptionDetail?.planStatus ===
                PLAN_STATUS?.CANCELLED ||
                userSubscriptionInfo?.subscription?.id === null ||
                spaceSubscriptionDetail?.id === null ||
                spaceSubscriptionDetail?.planStatus === PLAN_STATUS?.TRIAL ||
                moment(spaceSubscriptionDetail?.endDate) < moment()) &&
                spaceSubscriptionDetail?.planStatus !==
                  PLAN_STATUS?.PAST_DUE && (
                  <div>
                    <IconButton
                      iconClass="font-family cms-plan-subs-btn cms-plan-subs-select-btn"
                      text={isLTDPlan ? "Activated" : "Activate"}
                      disable={isLTDPlan}
                      handleClick={() =>
                        handleNewSpacePlan(
                          plan?.price?.id,
                          plan?.price?.amount,
                          plan?.price?.currency,
                          plan?.name,
                          plan?.price?.interval,
                          plan
                        )
                      }
                    />
                  </div>
                )}
              {spaceSubscriptionDetail?.product?.price?.id ===
                plan?.price?.id &&
                planEndDate > moment() &&
                spaceSubscriptionDetail?.planStatus === PLAN_STATUS?.ACTIVE && (
                  <div>
                    <IconButton
                      iconClass="font-family cms-plan-subs-btn cms-plan-subs-active-btn"
                      text={isLTDPlan ? "Activated" : "Update"}
                      disable={isLTDPlan}
                      handleClick={() =>
                        handleUpdatePopup(
                          plan?.price?.id,
                          plan?.price?.amount,
                          plan?.price?.currency,
                          plan?.name,
                          plan?.price?.interval,
                          plan
                        )
                      }
                    />
                  </div>
                )}
              {spaceSubscriptionDetail?.planStatus ===
                PLAN_STATUS?.PAST_DUE && (
                <div>
                  <IconButton
                    iconClass="cms-btn-activate font-family cms-plan-subs-btn cms-plan-subs-active-btn"
                    text={isLTDPlan ? "Activated" : "Activate"}
                    disable={isLTDPlan}
                    handleClick={() =>
                      handleNewSpacePlan(
                        plan?.price?.id,
                        plan?.price?.amount,
                        plan?.price?.currency,
                        plan?.name,
                        plan?.price?.interval,
                        plan
                      )
                    }
                  />
                </div>
              )}
              {planEndDate >= moment() &&
                spaceSubscriptionDetail?.planStatus === PLAN_STATUS?.ACTIVE &&
                spaceSubscriptionDetail?.product?.price?.id !==
                  plan?.price?.id && (
                  <div>
                    <IconButton
                      iconClass="cms-btn-activate font-family cms-plan-subs-btn cms-plan-subs-update-btn"
                      text={isLTDPlan ? "Activated" : "Activate"}
                      disable={isLTDPlan}
                      handleClick={() =>
                        handleUpdatePopup(
                          plan?.price?.id,
                          plan?.price?.amount,
                          plan?.price?.currency,
                          plan?.name,
                          plan?.price?.interval,
                          plan
                        )
                      }
                      loading={newSpaceLoader}
                    />
                  </div>
                )}
            </div>
          ) : (
            //! For Basic and Business plan and pro+ plan
            <div
              className={`${
                spaceSubscriptionDetail?.product?.price?.id ===
                  plan?.price?.id &&
                spaceSubscriptionDetail?.planStatus === PLAN_STATUS?.ACTIVE &&
                "cms-plan-active"
              } ${isLTDPlan && "width-ltd"} cms-subs-plan`}
              key={plan?.price?.id}
            >
              <div className="cms-subs-plan-title cms-plan-title-other">
                <div className="cms-subs-active-plan">
                  <span className="font-inter cms-plan-title-text">
                    {plan?.name} {!isLTDPlan && "Plan"}
                  </span>
                </div>
              </div>
              <div className="cms-subs-plan-title">
                <div className="cms-plan-price-detail cms-font-ellipsis">
                  <span className="font-inter cms-plan-price">
                    {plan?.price?.currency === "inr" ? "Rs." : "$"}
                    {
                      // eslint-disable-next-line no-nested-ternary
                      plan?.name === PLAN_NAMES?.INDIVIDUAL
                        ? plan?.price?.amount
                            ?.toFixed(2)
                            ?.replace(/[.,]00$/, "")
                        : // eslint-disable-next-line no-nested-ternary
                        isLTDPlan
                        ? plan?.price?.amount
                            ?.toFixed(2)
                            ?.replace(/[.,]00$/, "")
                        : selectedInterval === MONTH
                        ? plan?.price?.amount
                            ?.toFixed(2)
                            ?.replace(/[.,]00$/, "")
                        : (plan?.price?.amount / 12)
                            ?.toFixed(2)
                            ?.replace(/[.,]00$/, "")
                    }
                  </span>
                  {!isLTDPlan && plan?.name !== PLAN_NAMES?.INDIVIDUAL && (
                    <span className="font-inter cms-plan-per-month">
                      /month
                    </span>
                  )}
                </div>
              </div>
              {!isLTDPlan && plan?.name !== PLAN_NAMES?.INDIVIDUAL && (
                <div className="cms-subs-plan-title per-member-title cms-plan-title-other">
                  <div className="cms-plan-price-detail cms-font-ellipsis">
                    <span className="font-inter cms-plan-per-user">
                      per member
                    </span>
                  </div>
                </div>
              )}
              {!isLTDPlan && plan?.name === PLAN_NAMES?.INDIVIDUAL && (
                <div className="cms-subs-plan-title per-member-title cms-plan-title-other">
                  <div className="cms-plan-price-detail cms-font-ellipsis">
                    <span className="font-inter cms-plan-per-user">
                      for one member
                    </span>
                  </div>
                </div>
              )}
              <div className={`${!isLTDPlan && "cms-subs-plan-feature-list"}`}>
                <SetSubscriptionFeatures
                  features={plan?.features}
                  planName={plan?.name}
                  isLTDPlan={isLTDPlan}
                />
              </div>
              {isLTDPlan && (
                <IconButton
                  iconClass="font-family cms-plan-subs-btn cms-plan-subs-active-btn"
                  text="Activated"
                  disable
                />
              )}
              {!isLTDPlan &&
                (spaceSubscriptionDetail?.planStatus ===
                  PLAN_STATUS?.CANCELLED ||
                  spaceSubscriptionDetail?.planStatus === PLAN_STATUS?.TRIAL ||
                  spaceSubscriptionDetail?.id === null ||
                  userSubscriptionInfo?.subscription?.id === null ||
                  moment(spaceSubscriptionDetail?.endDate) < moment()) &&
                spaceSubscriptionDetail?.planStatus !==
                  PLAN_STATUS?.PAST_DUE && (
                  <div>
                    <IconButton
                      iconClass="font-family cms-plan-subs-btn cms-plan-subs-select-btn"
                      text="Activate"
                      handleClick={() =>
                        handleNewSpacePlan(
                          plan?.price?.id,
                          plan?.price?.amount,
                          plan?.price?.currency,
                          plan?.name,
                          plan?.price?.interval,
                          plan
                        )
                      }
                    />
                  </div>
                )}
              {!isLTDPlan &&
                spaceSubscriptionDetail?.product?.price?.id ===
                  plan?.price?.id &&
                planEndDate > moment() &&
                spaceSubscriptionDetail?.planStatus === PLAN_STATUS?.ACTIVE &&
                spaceSubscriptionDetail?.product?.name !==
                  PLAN_NAMES?.INDIVIDUAL && (
                  <div>
                    <IconButton
                      iconClass=" font-family cms-plan-subs-btn cms-plan-subs-active-btn"
                      text="Update"
                      handleClick={() =>
                        handleUpdatePopup(
                          plan?.price?.id,
                          plan?.price?.amount,
                          plan?.price?.currency,
                          plan?.name,
                          plan?.price?.interval,
                          plan
                        )
                      }
                    />
                  </div>
                )}
              {!isLTDPlan &&
                spaceSubscriptionDetail?.product?.price?.id ===
                  plan?.price?.id &&
                planEndDate > moment() &&
                spaceSubscriptionDetail?.planStatus === PLAN_STATUS?.ACTIVE &&
                spaceSubscriptionDetail?.product?.name ===
                  PLAN_NAMES?.INDIVIDUAL && (
                  <IconButton
                    iconClass="font-family cms-plan-subs-btn cms-plan-subs-active-btn"
                    text="Activated"
                    disable
                  />
                )}
              {!isLTDPlan &&
                spaceSubscriptionDetail?.planStatus ===
                  PLAN_STATUS?.PAST_DUE && (
                  <div>
                    <IconButton
                      iconClass="cms-btn-activate font-family cms-plan-subs-btn cms-plan-subs-active-btn"
                      text="Activate"
                      handleClick={() =>
                        handleUpdatePopup(
                          plan?.price?.id,
                          plan?.price?.amount,
                          plan?.price?.currency,
                          plan?.name,
                          plan?.price?.interval,
                          plan
                        )
                      }
                    />
                  </div>
                )}
              {!isLTDPlan &&
                planEndDate >= moment() &&
                spaceSubscriptionDetail?.planStatus === PLAN_STATUS?.ACTIVE &&
                spaceSubscriptionDetail?.product?.price?.id !==
                  plan?.price?.id && (
                  <div>
                    <IconButton
                      iconClass="cms-btn-activate font-family cms-plan-subs-btn cms-plan-subs-update-btn"
                      text="Activate"
                      handleClick={() =>
                        handleUpdatePopup(
                          plan?.price?.id,
                          plan?.price?.amount,
                          plan?.price?.currency,
                          plan?.name,
                          plan?.price?.interval,
                          plan
                        )
                      }
                    />
                  </div>
                )}
            </div>
          )
        )}
      </div>

      {/* //! Activate Modal */}

      <Modal
        className="cms-plan-update-popup"
        visible={open}
        onCancel={closeUpdatePopup}
        centered
        footer={
          <IconButton
            iconClass="font-family cms-plan-subs-btn cms-plan-subs-active-btn "
            text="Confirm"
            handleClick={() =>
              handleUpdateSubscription(
                subscriptionChanges?.newPlan?.price?.id,
                subscriptionChanges?.newPlan,
                selectedPlan
              )
            }
            disable={
              (updatedUserCount === spaceDetail?.subscription?.quantity &&
                spaceDetail?.subscription?.product?.price?.interval ===
                  selectedInterval &&
                spaceDetail?.subscription?.product?.price?.id ===
                  selectedPlan?.priceId) ||
              updatedUserCount < spaceDetail?.membersCount ||
              updatedUserCount === null ||
              updatedUserCount === "" ||
              updatedUserCount > maximumUserCount ||
              (userSubscriptionInfo?.type === GOOGLE
                ? updatedUserCount < 5
                : updatedUserCount < 3)
            }
            loading={updateLoader}
          />
        }
        title={<span className="font-inter">Confirm Subscription</span>}
      >
        <div className="popup-content">
          <div className="cms-modal-content-title cms-subs-plan">
            <div className="cms-subs-plan-title cms-plan-title-other">
              <div className="cms-subs-active-plan">
                <span className="font-inter cms-plan-title-text">
                  {`${selectedPlan?.planName} Plan`}
                </span>
              </div>
            </div>
            {selectedPlan?.planName !== PLAN_NAMES?.INDIVIDUAL && (
              <>
                <div className="font-inter cms-modal-content-heading">
                  How many members do you want to add?
                </div>
                <div className="cms-modal-content-sub-heading">
                  This will be the number of members you want to keep shared
                  contacts with.
                </div>
                <div
                  className={`${
                    updatedUserCount < spaceDetail?.membersCount &&
                    "cms-input-number-text-danger"
                  } cms-input-number`}
                >
                  <Input
                    type="number"
                    defaultValue={userDefaultValue}
                    min={userSubscriptionInfo?.type === GOOGLE ? 5 : 3}
                    value={updatedUserCount}
                    onChange={(value) => {
                      setUpdatedUserCount(
                        parseInt(value?.target?.value, 10) || 0
                      );
                    }}
                    className={`${
                      updatedUserCount <= spaceDetail?.membersCount &&
                      "cms-input-number-text-danger"
                    } "cms-input-number-text-danger"`}
                  />

                  <div className="font-inter cms-plan-price">
                    {" "}
                    <span className="cms-font-cross"> X </span>
                    {getCurrencySymbol(selectedPlan?.currency)}
                    {selectedPlan?.interval === "year"
                      ? (selectedPlan?.price / 12)?.toFixed(2)
                      : selectedPlan?.price?.toFixed(2)}{" "}
                    {selectedPlan?.interval === "year" && (
                      <span className="font-inter cms-plan-year">
                        <span className="cms-font-cross"> X </span> 12 months
                      </span>
                    )}
                  </div>
                  <Divider type="vertical" />
                  {retrieveLoader ? (
                    <Skeleton active paragraph={false} />
                  ) : (
                    <div>
                      <div className="font-inter cms-total-amount-text">
                        Next billing:
                      </div>
                      <div className="">
                        <span className="cms-total-amount">
                          {" "}
                          {`${getCurrencySymbol(
                            subscriptionChanges?.newPlan?.price?.currency
                          )}${(
                            subscriptionChanges?.nextInvoiceTotal / 100
                          )?.toFixed(2)}`}{" "}
                        </span>
                        <span className="cms-bill-tag">
                          Billed{" "}
                          {selectedPlan?.interval === "month"
                            ? "monthly"
                            : "annually"}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
            {updatedUserCount < spaceDetail?.membersCount && (
              <div className="cms-warning-text font-inter">
                {" "}
                <WarningIcon className="mr-6 mt-6" />
                You already have {spaceDetail?.membersCount} members in your
                workspace.
              </div>
            )}
            {userSubscriptionInfo?.type === GOOGLE
              ? updatedUserCount < 5
              : updatedUserCount < 3 && (
                  <div className="cms-warning-text font-inter">
                    {" "}
                    <WarningIcon className="mr-6 mt-6" />
                    Users cannot be less than{" "}
                    {userSubscriptionInfo?.type === GOOGLE ? 5 : 3}{" "}
                  </div>
                )}
            {updatedUserCount > maximumUserCount && (
              <div className="cms-warning-text font-inter">
                {" "}
                <WarningIcon className="mr-6 mt-6" />
                Members can not be more than {maximumUserCount}. Contact Support
                for enterprise pricing.
              </div>
            )}
            <Divider />
            {retrieveLoader ? (
              <Skeleton active paragraph={false} size="small" />
            ) : (
              <>
                <div className="font-inter cms-total-amount-text">
                  Payable amount:
                </div>
                <div className="align-center">
                  <span className="cms-total-amount">
                    {" "}
                    {`${getCurrencySymbol(
                      subscriptionChanges?.newPlan?.price?.currency
                    )}${
                      subscriptionChanges?.immediateTotal / 100 < 0
                        ? "0"
                        : subscriptionChanges?.immediateTotal / 100
                    }`}{" "}
                  </span>
                  {spaceDetail?.subscription?.quantity < updatedUserCount && (
                    <span className="cms-additional-members">
                      (for additional members)
                    </span>
                  )}
                </div>
                {creditText && (
                  <div className="cms-warning-text font-inter">
                    {" "}
                    <WarningIcon className="mr-6 mt-6" />
                    The remaining amount will be pro-rated and used as credits
                    for next billing cycles.
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Modal>

      {/* {//! Confirm Popup} */}
      <Modal
        className="cms-plan-update-popup cms-plan-confirm-popup"
        visible={confirmPopup}
        onCancel={closeConfirmPopup}
        centered
        title={<span className="font-inter">Confirm Subscription</span>}
        footer={
          <IconButton
            iconClass="font-family cms-plan-subs-btn cms-plan-subs-active-btn"
            text="Confirm"
            handleClick={() =>
              handleSpaceSubscription(selectedPlan?.priceId, selectedPlan)
            }
            disable={
              updatedUserCount === "" ||
              (userSubscriptionInfo?.type === GOOGLE
                ? updatedUserCount < 5
                : updatedUserCount < 3) ||
              updatedUserCount > maximumUserCount
            }
            loading={newSpaceLoader}
          />
        }
      >
        <div className="popup-content">
          <div className="cms-modal-content-title cms-subs-plan">
            <div className="cms-subs-plan-title cms-plan-title-other">
              <div className="cms-subs-active-plan">
                <span className="font-inter cms-plan-title-text">
                  {`${selectedPlan?.planName} Plan`}
                </span>
              </div>
            </div>
            {selectedPlan?.planName !== PLAN_NAMES?.INDIVIDUAL && (
              <>
                <div className="font-inter cms-modal-content-heading">
                  How many members do you want to add?
                </div>
                <div className="cms-modal-content-sub-heading">
                  This will be the number of members you want to keep shared
                  contacts with.
                </div>

                <div
                  className={`${
                    updatedUserCount <= spaceDetail?.membersCount &&
                    "cms-input-number-text-danger"
                  } cms-input-number`}
                >
                  <Input
                    type="number"
                    step={1}
                    defaultValue={userDefaultValue}
                    min={userSubscriptionInfo?.type === GOOGLE ? 5 : 3}
                    value={updatedUserCount}
                    onChange={(value) => {
                      setUpdatedUserCount(
                        parseInt(value?.target?.value, 10) || 0
                      );
                    }}
                    className={`${
                      updatedUserCount <= spaceDetail?.membersCount &&
                      "cms-input-number-text-danger"
                    } "cms-input-number-text-danger"`}
                  />
                  <div className="font-inter cms-plan-price">
                    {" "}
                    <span className="cms-font-cross"> X </span>
                    {getCurrencySymbol(selectedPlan?.currency)}
                    {selectedPlan?.interval === "year"
                      ? (selectedPlan?.price / 12)?.toFixed(2)
                      : selectedPlan?.price?.toFixed(2)}{" "}
                    {selectedPlan?.interval === "year" && (
                      <span className="font-inter cms-plan-year">
                        <span className="cms-font-cross"> X </span> 12 months
                      </span>
                    )}
                  </div>
                </div>

                {updatedUserCount <= spaceDetail?.membersCount && (
                  <div className="cms-warning-text font-inter">
                    {" "}
                    <WarningIcon className="mr-6 mt-6" />
                    You already have {spaceDetail?.membersCount} members in your
                    workspace.
                  </div>
                )}

                {userSubscriptionInfo?.type === GOOGLE
                  ? updatedUserCount < 5
                  : updatedUserCount < 3 && (
                      <div className="cms-warning-text font-inter">
                        <WarningIcon className="mr-6 mt-6" />
                        Users cannot be less than{" "}
                        {userSubscriptionInfo?.type === GOOGLE ? 5 : 3}
                      </div>
                    )}
                {updatedUserCount > maximumUserCount && (
                  <div className="cms-warning-text font-inter">
                    {" "}
                    <WarningIcon className="mr-6 mt-6" />
                    Members can not be more than {maximumUserCount}. Contact
                    Support for enterprise pricing.
                  </div>
                )}
              </>
            )}

            <Divider />
            {retrieveLoader ? (
              <Skeleton active paragraph={false} size="small" />
            ) : (
              <>
                <div className="font-inter cms-total-amount-text">
                  Payable amount:
                </div>
                <div className="">
                  {selectedPlan?.planName === PLAN_NAMES?.INDIVIDUAL ? (
                    <span className="cms-total-amount">
                      {" "}
                      {`${getCurrencySymbol(
                        selectedPlan?.currency
                      )}${selectedPlan?.price?.toFixed(2)}
                      `}{" "}
                    </span>
                  ) : (
                    <span className="cms-total-amount">
                      {" "}
                      {`${getCurrencySymbol(selectedPlan?.currency)}${
                        selectedInterval === "YEAR"
                          ? (
                              selectedPlan?.price *
                              updatedUserCount *
                              12
                            )?.toFixed(2)
                          : (selectedPlan?.price * updatedUserCount)?.toFixed(2)
                      }
                      `}{" "}
                    </span>
                  )}

                  <span className="cms-bill-tag">
                    Billed{" "}
                    {selectedPlan?.interval === "month"
                      ? "monthly"
                      : "annually"}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
      <RemovePopup
        visible={limitExceedPopup}
        title="Space sharing limit exceed"
        content={
          <span className="popup-text-title font-inter popup-text-title-info">
            You can not update the plan because you are either using features
            from a higher plan OR you have more shared users than the selected
            number of users.
          </span>
        }
        okText="Okay"
        removeNote={closePopup}
        handleRemove={closePopup}
      />
      <RemovePopup
        visible={creditPopup}
        title="Activate Plan"
        content={
          <span className="popup-text-title font-inter popup-text-title-info">
            You are about to switch to a plan which either has a lower plan or
            lower number of shared users. The amount will be pro-rated and
            stored as credits which will be used for next billing cycle. Please
            contact support for more clarity.
          </span>
        }
        okText="Pay Now"
        removeNote={closeCreditPopup}
        handleRemove={() =>
          handleUpdateSubscription(
            subscriptionChanges?.newPlan?.price?.id,
            subscriptionChanges?.newPlan,
            selectedPlan
          )
        }
        deleteLoader={updateLoader}
        disabled={updateLoader}
      />
    </>
  );
};

export default SubscriptionPlans;
